import React from "react";
import PropTypes from "prop-types";
import {
	Card,
	CardHeader,
	CardBody,
	ListGroup,
	ListGroupItem,
	Button,
	InputGroup,
	InputGroupAddon,
	FormCheckbox,
	FormInput,
	Row,
	Col,
	ButtonGroup,
} from "shards-react";

const SidebarChannels = ({
	title,
	channels,
	publicChannels,
	handleCheck,
	handleCheckPublic,
	checkedPublicChannel,
	checkedRooms,
}) => {
	console.log("🚀 ~ file: SidebarChannels.js:28 ~ channels:", channels);

	return (
		<>
			<Row>
				<Col>
					<ButtonGroup>
						<Button className="blue-banner channel-buttons scriber-bold">
							SCHOOL
						</Button>
						<Button className="blue-banner channel-buttons scriber-bold">
							CINEMA
						</Button>
						<Button className="blue-banner channel-buttons scriber-bold">
							RADIO
						</Button>
					</ButtonGroup>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card small className="rounded-0 blue-border">
						<CardHeader className="border-bottom">
							<h6 className="m-0">{title}</h6>
						</CardHeader>
						<CardBody className="p-0">
							<ListGroup flush style={{ height: "32rem" }}>
								<ListGroupItem className="px-3 pb-2 overflow-auto">
									{channels &&
										channels.map((channel, index) => (
											<FormCheckbox
												key={index}
												className="mb-1"
												checked={checkedRooms.some(
													(room) => room.key === channel.key
												)}
												name={channel.title}
												onChange={() => handleCheck(channel)}
											>
												{channel.title}
											</FormCheckbox>
										))}

									<div>Public Channels</div>
									{publicChannels &&
										publicChannels.map((publicChannel, index) => (
											<FormCheckbox
												key={index}
												className="mb-1"
												checked={checkedPublicChannel.some(
													(room) => room.channelId === publicChannel.channelId
												)} // corrected variable name and comparison
												name={publicChannel.channelName}
												onChange={() => handleCheckPublic(publicChannel)}
											>
												{publicChannel.channelName}
											</FormCheckbox>
										))}
								</ListGroupItem>

								<ListGroupItem className="d-flex px-3">
									<InputGroup className="ml-auto">
										<FormInput placeholder="New channel" />
										<InputGroupAddon type="append">
											<Button theme="white" className="px-2">
												<i className="material-icons">add</i>
											</Button>
										</InputGroupAddon>
									</InputGroup>
								</ListGroupItem>
							</ListGroup>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

SidebarChannels.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

SidebarChannels.defaultProps = {
	title: "Channels",
};

export default SidebarChannels;
